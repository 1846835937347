<template>
  <a-drawer class="news-add" title="创建新闻" @close="onClose" :width="720" :visible="newsAddVisiable" :maskClosable="false">
    <a-form-model ref="ruleForm" :model="form" :rules="rules">
      <a-form-model-item label='标题' prop="title" v-bind="formItemLayout">
        <a-input v-model="form.title" class="input-style"></a-input>
      </a-form-model-item>
      <a-form-model-item label='正文' prop="news" v-bind="formItemLayout">
        <editor name="news" :value.sync="form.news" :height="300" @update-value="updateValue"></editor>
      </a-form-model-item>
      <a-form-model-item label="新闻图片" prop="" v-bind="formItemLayout">
        <UploadImgs :path="IMAGE_NEWS" :limit="1" :file-list="fileList" @_change="handleUploadImgChange"></UploadImgs>
        <a-tag color="orange">建议图片尺寸：400*400px</a-tag>
      </a-form-model-item>
      <a-form-model-item label="发布时间" prop="createTime" v-bind="formItemLayout" >
        <a-date-picker format="YYYY-MM-DD HH:mm:ss" v-model="form.createTime" :show-time="{ defaultValue: moment('00:00:00', 'HH:mm:ss') }" />
      </a-form-model-item>
    </a-form-model>
    <div class="drawer-bootom-button">
      <!-- <a-popconfirm title="确定放弃编辑？" @confirm="onClose" okText="确定" cancelText="取消">
        <a-button style="margin-right: .8rem">取消</a-button>
      </a-popconfirm> -->
			<a-button @click="onClose" style="margin-right: .8rem">取消</a-button>
      <a-button @click="handleSubmit" type="primary">提交</a-button>
    </div>
  </a-drawer>
</template>

<script>
import moment from "moment";
import Editor from "../../../components/editor/editor"
import UploadImgs from "../../../components/upload-imgs/UploadImgs"
import {IMAGE_NEWS} from "../../../utils/upload-path-map"

const formItemLayout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 18 }
}

export default {
  components: {
    Editor,
    UploadImgs
  },
  name: "NewsAdd",
  props: {
    newsAddVisiable: {
      default: false
    }
  },
  data() {
    return {
      formItemLayout,
      IMAGE_NEWS,
      fileList: [],
      form: {
        title: "",
        news: "",
        userList: "",
        createTime: moment(),
        image: ""
      },
      rules: {
        title: [
          { required: true, message: '标题不能为空', trigger: 'blur' }
        ],
        news: [
          { required: true, message: '新闻内容不能为空', trigger: 'blur' }
        ],
        userList: [
          { required: true, message: '对象组不能为空', trigger: 'change' }
        ],
        image: [
          { required: true, message: '新闻图片不能为空', trigger: 'change' }
        ],
      }
    }
  },
  mounted() {
  },
  methods: {
    moment,
    onClose () {
			this.reset();
      this.$emit('close');
    },
		reset() {
		  this.$refs.ruleForm.resetFields();
		  for (let key in this.form) {
		    this.form[key] = undefined;
		  }
      this.form.createTime = moment()
		  this.fileList = [];
		},
    updateValue(val, key='') {
      // console.log("key: ", key, "val: ", val);
      this.form[key] = val;
    },
    handleSubmit() {
      const companyNews = JSON.parse(JSON.stringify(this.form));
      companyNews.createTime = moment(companyNews.createTime).valueOf();
      this.$post("company_news", {...companyNews}).then(r => {
				this.reset();
        this.$emit('success');
      })
    },
    handleUploadImgChange(fileList, urls) {
      // console.log("上传图片组件传过来的值", urls);
      // this.form.image = JSON.stringify(urls);
      this.form.image = urls.length?urls[0]['filePath']:'';
      this.fileList = fileList;
    },
  }
}
</script>

<style lang="less" scoped>
.news-add {
  height: calc(100% - 55px);
  overflow: auto;
  padding-bottom: 53px;
  .input-style {
    width: 100%;
  }
}
</style>